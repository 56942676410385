import React from 'react'
import Button from '~/core/ui/Button'
import { motion, useAnimationControls } from 'framer-motion'
import classNames from 'clsx'

interface MainCallToActionButtonProps {
  href: string
  text: string
}

const MainCallToActionButton: React.FC<MainCallToActionButtonProps> = ({ href, text }) => {
  const shimmerControls = useAnimationControls()

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
      onHoverStart={() => {
        shimmerControls.start({
          x: ['-200%', '200%'],
          skew: ['0deg', '-22deg', '0deg'],
          transition: {
            duration: 1.5,
            ease: 'easeOut',
            repeat: 0, // Run only once
            delay: 0,
          },
        })
      }}
      onHoverEnd={() => {
        shimmerControls.stop()
        shimmerControls.set({ x: '-200%', skew: '0deg' }) // Reset to initial position
      }}
    >
      <Button
        className={classNames(
          'relative overflow-hidden',
          'bg-gradient-to-r from-primary-500 to-primary-600',
          'text-white shadow-2xl',
          'hover:shadow-primary/60 hover:to-primary-600',
          'transition-all ease-in-out duration-300',
          'fill-mode-both',
          'rounded-3xl',
          'text-sm sm:text-lg xl:text-xl',
          'py-2.5 sm:py-4 xl:py-5 sm:px-8 xl:px-10',
        )}
        variant="custom"
        href={href}
      >
        <span className="flex items-center space-x-2 relative z-10">
          <span>{text}</span>
        </span>
        <motion.div
          animate={shimmerControls}
          initial={{ x: '-200%', skew: '0deg' }}
          className="absolute inset-0 bg-gradient-to-r from-transparent via-cyan-100 to-transparent opacity-40"
        />
      </Button>
    </motion.div>
  )
}

export default MainCallToActionButton
