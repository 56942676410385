import { useFetcher } from '@remix-run/react'
import { type FormEventHandler, useEffect, useState } from 'react'
import { toast } from 'sonner'
import Button from '~/core/ui/Button'
import Modal from '~/core/ui/Modal'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '~/core/ui/Select'

export default function MoveFileIntoProjectModal({
  open,
  setOpen,
  projects,
  onConfirm,
  title,
}: React.PropsWithChildren<{
  title?: string
  onConfirm: (destinationProjectId: string) => void
  open: boolean
  setOpen: (open: boolean) => void
  projects: { id: string, title: string }[]
}>) {
  const [projectId, setProjectId] = useState(projects[0]?.id)
  const fetcher = useFetcher()
  const submitting = fetcher.state === 'submitting'

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    onConfirm(projectId);
    setOpen(false);
  };
  useEffect(() => {
    if (!projects.length && open) {
      toast.error('There are no other projects to move in.');
    }
  }, [open, projects.length]);
  if (!projects.length) {
    return null;
  }
  return (
    <>
      <Modal heading="Move File" isOpen={open} setIsOpen={setOpen}>
        <form onSubmit={onSubmit} className="space-y-2">
          <p className="text-[#64748B] pb-5">{title || 'Move this file to different project folder'}</p>
          <Select
            value={projectId}
            onValueChange={value => setProjectId(value)}
            name="usage-timeframe"
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select project" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {projects.map(({ title, id }) => (
                  <SelectItem key={id} value={id}>{title}</SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <div className="flex space-x-2">
            <Button loading={submitting} type="submit">
              Move
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
