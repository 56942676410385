import { Link, useLoaderData, useLocation, useSearchParams } from "@remix-run/react"
import { useMemo, useState } from "react"
import { Project, Transcript } from "~/lib/transcript/queries"
import ConfirmDeleteTranscriptModal from "./ConfirmDeleteTranscriptModal"
import EditTranscriptModal from "./EditTranscriptModal"
import { Checkbox } from "~/core/ui/Checkbox"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "~/core/ui/Dropdown"
import { EllipsisVerticalIcon } from "lucide-react"
import MoveFileIntoProjectModal from "~/core/ui/MoveFileIntoProjectModal"

type ModalType = 'edit' | 'delete' | 'move' | null

interface ProjectActionSettingsProps {
  status: Transcript["status"];
  transcriptionId: string
  transcription: string
  deleteTranscript: (id: string | string[]) => void
  editTranscript: (params: { id: string, title: string }) => void
  moveTranscript: (
    params: {
      id: string | string[]
      currentProjectId: string
      destinationProjectId: string
    }) => void
  isChecked: boolean
  changeIsCheckedStatus: (transcriptId: string) => void
}

export function ProjectActionSettings({
  status,
  transcriptionId,
  transcription,
  deleteTranscript,
  editTranscript,
  moveTranscript,
  isChecked,
  changeIsCheckedStatus,
}: ProjectActionSettingsProps) {
  const { projectsLabelList, project, transcripts } = useLoaderData<{
    projectsLabelList: { id: string, title: string }[]
    project: Project
    transcripts: Transcript[]
  }>()
  const [openModalType, setOpenModalType] = useState<ModalType>(null)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const selectedTranscriptions = searchParams.getAll('transcriptionId')

  const transcriptionIdToPass = selectedTranscriptions.length < 2
    ? transcriptionId
    : selectedTranscriptions

  const transcriptTitle = useMemo(() => {
    // If user selected multiple transcriptions we need to concat each
    // title into single string
    if (Array.isArray(transcriptionIdToPass)) {
      return transcripts
        .filter(({ id }) => transcriptionIdToPass.includes(id))
        .map(({ title }) => title)
        .join(', ')
    }
    // If user selected 1 or zero transcriptions we need to return
    // current clicked transcription
    return transcription
  }, [selectedTranscriptions])
  return (
    <>
      <ConfirmDeleteTranscriptModal
        title={
          selectedTranscriptions.length >= 2
            ? `Are you about to delete ${selectedTranscriptions.length} transcriptions: `
            : undefined // Otherwise rendering default title
        }
        open={openModalType === 'delete'}
        onConfirm={() => deleteTranscript(
          transcriptionIdToPass,
        )}
        transcriptTitle={transcriptTitle}
        setOpen={() => setOpenModalType(null)}
      />
      <EditTranscriptModal
        transcript={transcription}
        onConfirm={title => editTranscript({ title, id: transcriptionId })}
        open={openModalType === 'edit'}
        setOpen={() => setOpenModalType(null)}
      />
      <MoveFileIntoProjectModal
        open={openModalType === 'move'}
        title={selectedTranscriptions.length >= 2
          ? `
          Move these ${selectedTranscriptions.length} files to different project folder
          `
          : undefined} // Otherwise rendering default title
        onConfirm={destinationProjectId =>
          moveTranscript({
            currentProjectId: project.id,
            id: transcriptionIdToPass,
            destinationProjectId,
          })}
        projects={projectsLabelList}
        setOpen={() => setOpenModalType(null)}
      />
      <div className="flex justify-end gap-4 items-center text-[14px]">
        <Link className="mr-4 hover:underline" to={`${location.pathname}/transcription/${transcriptionId}`}>
          View
        </Link>
        <Checkbox
          disabled={status !== 'COMPLETED'}
          checked={isChecked}
          onClick={() => changeIsCheckedStatus(transcriptionId)}
        />

        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <EllipsisVerticalIcon className="w-5 cursor-pointer" />
          </DropdownMenuTrigger>

          <DropdownMenuContent
            collisionPadding={{
              right: 20,
            }}
          >
            <DropdownMenuItem
              onSelect={() => {
                setOpenModalType('move')
              }}
            >
              Move
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setOpenModalType('edit')
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setOpenModalType('delete')
              }}
              className="text-red-500"
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  )
}