import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

import { Link } from '@remix-run/react';
import { ChevronDownIcon, FingerprintIcon, Grid2X2PlusIcon, LucideIcon, MousePointerClickIcon } from 'lucide-react';

const solutions = [
  {
    name: 'AI Topic Analysis',
    description: 'Rapid analysis of open-ended responses',
    href: '/solutions/topic-analysis',
    icon: MousePointerClickIcon,
  },
  {
    name: 'AI Transcription',
    description: "Generate insights and chat to your transcript",
    href: '/solutions/transcription',
    icon: FingerprintIcon,
  },
  {
    name: 'AI Researcher',
    description: 'Generate reports on demand using both web and local docs',
    href: '/solutions/ai-researcher',
    icon: Grid2X2PlusIcon,
  }
];
const callsToAction = [
  // { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  // { name: 'Contact sales', href: '#', icon: PhoneIcon },
] as { name: string; href: string; icon: LucideIcon }[];

export default function SolutionPopover() {
  return (
    <Popover className="relative">
      <PopoverButton
          className="inline-flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900 dark:text-white"
        >
            Solutions
          <ChevronDownIcon aria-hidden="true" className="size-5" />
      </PopoverButton>
      <PopoverPanel
        transition
        className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm/6 shadow-lg ring-1 ring-gray-900/5">
          <div className="p-4">
            {solutions.map((item) => (
              <div
                key={item.name}
                className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
              >
                <div className="mt-1 flex size-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  <item.icon
                    aria-hidden="true"
                    className="size-6 text-gray-600 group-hover:text-primary-500"
                  />
                </div>
                <div>
                  <a href={item.href} className="font-semibold text-gray-900">
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 text-gray-600">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
            {callsToAction.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                prefetch="viewport"
                className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
              >
                <item.icon
                  aria-hidden="true"
                  className="size-5 flex-none text-gray-400"
                />
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
