import { Trans } from 'react-i18next'
import { Close as DialogPrimitiveClose } from '@radix-ui/react-dialog'

import IconButton from '~/core/ui/IconButton'
import If from '~/core/ui/If'
import Button from '~/core/ui/Button'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '~/core/ui/Dialog'
import { XIcon } from 'lucide-react'

interface ControlledOpenProps {
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => unknown
}

interface TriggerProps {
  Trigger?: React.ReactNode
}

type Props = React.PropsWithChildren<
  {
    modal?: boolean
    heading: string | React.ReactNode
    closeButton?: boolean
    className?: string
    contentClassName?: string
    closeButtonClassName?: string
  } & (ControlledOpenProps & TriggerProps)
>

const Modal: React.FC<Props> & {
  CancelButton: typeof CancelButton
} = ({
  closeButton,
  heading,
  children,
  contentClassName,
  closeButtonClassName,
  ...props
}) => {
  const isControlled = 'isOpen' in props
  const useCloseButton = closeButton ?? true

  return (
    <Dialog
      modal={props.modal}
      open={props.isOpen}
      onOpenChange={props.setIsOpen}
    >
      <If condition={props.Trigger}>
        <DialogTrigger asChild>{props.Trigger}</DialogTrigger>
      </If>

      <DialogContent className={props?.className}>
        <div className={contentClassName ?? 'flex flex-col space-y-4 w-full'}>
          <div className="flex items-center">
            <DialogTitle className="flex w-full text-xl font-semibold text-current">
              {heading}
            </DialogTitle>
          </div>

          <div className="relative">{children}</div>

          <If condition={useCloseButton}>
            <DialogPrimitiveClose asChild>
              <IconButton
                className={closeButtonClassName ?? 'absolute right-4 top-0 flex items-center'}
                label="Close Modal"
                onClick={() => {
                  if (isControlled && props.setIsOpen) {
                    props.setIsOpen(false)
                  }
                }}
              >
                <XIcon className="h-6" />
                <span className="sr-only">Close</span>
              </IconButton>
            </DialogPrimitiveClose>
          </If>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default Modal

function CancelButton<Props extends React.ButtonHTMLAttributes<unknown>>(
  props: Props,
) {
  return (
    <Button
      type="button"
      data-cy="close-modal-button"
      variant="ghost"
      {...props}
    >
      <Trans i18nKey="common:cancel" />
    </Button>
  )
}

Modal.CancelButton = CancelButton
