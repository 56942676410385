import React, { useContext } from 'react'
import Button from '../../../Button'
import { FeedbackPopupContainer } from '~/components/FeedbackPopup'
import SidebarContext from '~/lib/contexts/sidebar'
import { MessagesSquareIcon } from 'lucide-react'

export const FeedbackButton: React.FC = () => {
  const { collapsed } = useContext(SidebarContext)
  return (
    <FeedbackPopupContainer>
      <Button variant="outline">
        <MessagesSquareIcon className="h-6" />
        {collapsed === false ? <span className="ml-1">Feedback</span> : null}
      </Button>
    </FeedbackPopupContainer>
  )
}
