import { Link, useRouteLoaderData } from '@remix-run/react'
import { Trans } from 'react-i18next'


import { useContext } from 'react'
import NAVIGATION_CONFIG from '../navigation.config'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/core/ui/Dropdown'

import useSignOut from '~/core/hooks/use-sign-out'

import Modal from '~/core/ui/Modal'
import Heading from '~/core/ui/Heading'

import OrganizationsSelector from '~/components/organizations/OrganizationsSelector'
import useUserOrganizationsQuery from '~/lib/organizations/hooks/use-user-organizations-query'
import UserSessionContext from '~/core/session/contexts/user-session'
import { shouldShowNavItem } from './AppSidebarNavigation'
import useCurrentOrganization from '~/lib/organizations/hooks/use-current-organization'
import type { loader } from '~/root'
import { LandmarkIcon, MenuIcon, SquareArrowLeftIcon } from 'lucide-react'

function MobileAppNavigation() {
  const data = useRouteLoaderData<typeof loader>('root')
  const ENV = data?.ENV
  const organization = useCurrentOrganization()

  const items = NAVIGATION_CONFIG.items.filter((item) => {
    return shouldShowNavItem(item, organization?.id, ENV?.STAGE)
  })
  const Links = items.map((item, index) => {
    if ('children' in item) {
      return item.children.map((child) => {
        return (
          <DropdownLink
            key={child.path}
            Icon={child.Icon}
            path={child.path}
            label={child.label}
          />
        )
      })
    }

    if ('divider' in item) {
      return <DropdownMenuSeparator key={index} />
    }

    return (
      <DropdownLink
        key={item.path}
        Icon={item.Icon}
        path={item.path}
        label={item.label}
      />
    )
  })

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button aria-label="Open the main menu">
          <MenuIcon className="h-9" />
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent sideOffset={10} className="w-screen rounded-none">
        <OrganisationMobileSelector />

        {Links}

        <DropdownMenuSeparator />
        <SignOutDropdownItem />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default MobileAppNavigation

function OrganisationMobileSelector() {
  const { userSession } = useContext(UserSessionContext)
  const userId = userSession?.data?.id as string

  const { data } = useUserOrganizationsQuery(userId, {
    enabled: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const userOrganizations = data ?? []

  if (!userOrganizations || userOrganizations.length < 2) {
    return null
  }

  return (
    <OrganizationsModal />
  )
}

function DropdownLink(
  props: React.PropsWithChildren<{
    path: string
    label: string
    Icon: React.ElementType
  }>,
) {
  return (
    <DropdownMenuItem asChild key={props.path}>
      <Link
        to={props.path}
        className="flex h-12 w-full items-center space-x-4"
      >
        <props.Icon className="h-6" />

        <span>
          <Trans i18nKey={props.label} defaults={props.label} />
        </span>
      </Link>
    </DropdownMenuItem>
  )
}

function SignOutDropdownItem() {
  const signOut = useSignOut()

  return (
    <DropdownMenuItem
      className="flex h-12 w-full items-center space-x-4"
      onClick={signOut}
    >
      <SquareArrowLeftIcon className="h-5" />

      <span>
        <Trans i18nKey="common:signOut" defaults="Sign out" />
      </span>
    </DropdownMenuItem>
  )
}

function OrganizationsModal() {
  return (
    <Modal
      Trigger={(
        <DropdownMenuItem
          className="h-12"
          onSelect={e => e.preventDefault()}
        >
          <button className="flex items-center space-x-4">
            <LandmarkIcon className="h-6" />

            <span>
              <Trans i18nKey="common:yourOrganizations" />
            </span>
          </button>
        </DropdownMenuItem>
      )}
      heading={<Trans i18nKey="common:yourOrganizations" />}
    >
      <div className="flex flex-col space-y-6 py-4">
        <Heading type={6}>Select an organization below to switch to it</Heading>

        <OrganizationsSelector displayName />
      </div>
    </Modal>
  )
}
